import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Banner.module.css';
import family_2 from '../../images/familia_2.svg';

//<img src="https://cdn.builder.io/api/v1/image/assets/TEMP/aaccb9935be175d682b87f21a454fb914694c2b46174fe9b7a89e8bd68715854?placeholderIfAbsent=true&apiKey=2e7ba8381ca4414b831475c37bf6e9cc" alt="SCMS Banner" className={styles.bannerImage} />
const Banner = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.banner}>
      <div className={styles.content}>
        <img src={family_2} alt="SCMS Banner" className={styles.bannerImage} />
        <div className={styles.textContent}>
          <p className={styles.description}>
          A SOCIEDADE CAXIENSE DE MÚTUO SOCORRO – PREVIDÊNCIA PRIVADA, é uma Entidade Aberta de Previdência 
Complementar, entidade sem fins lucrativos, regida pela Lei Complementar 109, de 29 de maio de 2001, com 
autorização para operar com número ilimitado de associados sem distinção de qualquer natureza, fundada em 
11 de novembro de 1887, na cidade de Caxias do Sul/RS. 
A SOCIEDADE CAXIENSE DE MÚTUO SOCORRO – PREVIDÊNCIA PRIVADA, é também identificada pela sigla 
<b> “SCMS”</b>. 
A SOCIEDADE CAXIENSE DE MÚTUO SOCORRO – PREVIDÊNCIA PRIVADA, tem por finalidade a instituição de 
Planos Previdenciários – Pecúlios.  
          </p>
          <button className={styles.ctaButton} onClick={() => navigate('/historia')}>Saiba mais</button>
        </div>
      </div>
    </section>
  );
};

export default Banner;