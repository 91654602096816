import React from 'react';
import styles from './InstitutionalHistory.module.css';
import Timeline from '../../../components/Timeline/Timeline';
import imagem_historia from '../../../images/institutional/historia_foto.png'

const InstitutionalHistory = () => {
  return (
    <main className={styles.mainContent}>
      <section className={styles.heroSection}>
        <h1 className={styles.pageTitle}>Nossa História</h1>
      </section>
      
      <section className={styles.historyContent}>
        <div className={styles.textImageWrapper}>
          <p className={styles.historyText}>A Sociedade Operária de Mútuo Socorro Príncipe de Nápoles ("Societá Operária di Mutuo Soccorso Príncipe di Nápoli in Caxias" – como consta em seu primeiro livro de Atas) teve sua origem na tradição dos jogos de bochas.
            <br /><br />
            Conta-se que no ano de 1887, um grupo de 70 imigrantes italianos jogadores de bocha costumavam reunir- se aos domingos, para praticar seu esporte e confraternizar no então campo dos Bugres, como às vezes faltava um companheiro de jogo, por motivo de doença e por não haver na localidade recursos médicos ou hospitalares na época, os demais comentaram então sobre as dificuldades provocadas pelas enfermidades de chefes de família, que se viam na dura contingência de deixar de trabalhar por vários períodos e às vezes até permanentemente, fazendo com que as respectivas famílias sofressem graves vicissitudes. Surgiu assim, a ideia do auxílio mútuo, às práticas do espírito de solidariedade, por meio de uma sociedade de mútuo socorro, destinada a prestar auxílio aos sócios em caso de doença, invalidez ou morte mediante uma contribuição mensal.
            <br /><br />
            A primeira reunião foi na casa de Daniel Benetti, que se tornou o primeiro presidente. No encontro foi fixada a mensalidade inicial de 500 réis. O auxílio doença variava de acordo com a gravidade e duração da doença. Por morte do sócio, a viúva tinha direito a um auxílio em dinheiro, correspondente ao atual "pecúlio".
            <br /><br />
            A partir de 1894 com cerca de 400 contribuintes, a sociedade instituiu duas categorias de associados, os integrantes da primeira categoria contribuíam com 1 mil réis e os da segunda com 500 réis. E assim foi se desenvolvendo a sociedade, que mesmo com o passar dos anos continuou sempre cultivando seu espírito de confraternização manifestado na continuidade dos jogos de bocha ou nos almoços e jantares realizados na sede da entidade. A primeira sede da sociedade foi inaugurada em seu edifício na data de 12 de novembro de 1898, como consta o registro daquela solenidade.
            <br /><br />
            Em 1939 contava com quase mil sócios titulares (excluídas suas famílias e dependentes), um patrimônio de mais de 143 contos de réis e uma caixa de pecúlios de mais de 84 contos, estando, conforme atesta a imprensa, em "situação privilegiada e invejável.
            <br /><br />
            Com a eclosão da II Guerra Mundial, a diretoria da sociedade enfrentou alguns problemas devido a sua origem italiana e então decidiu mudar o nome da entidade para Sociedade Caxiense de Mútuo Socorro (SCMS), nome atual da Entidade.
            <br /><br />
            Em sua história a comunidade caxiense viu crescer a SCMS, que em 1962 já contava com aproximadamente 6.000 sócios. Em 1966, com a incorporação do pecúlio La Salle de Porto Alegre, ampliou este número para 10.000. E assim foi consolidando sua participação no cenário de previdência complementar brasileiro, superando as adequações provocadas pelo advento da Lei 6.435 de 1977. Em 11 de novembro de 2024 completou 137 anos de existência, marco que certamente ficará no seio da Sociedade Caxiense de Mútuo Socorro e na cidade de Caxias do Sul - RS, oferecendo ao mercado, planos de pecúlio por morte.
          </p>
          <img 
            src={imagem_historia} 
            alt="Imagem histórica da SCMS" 
            className={styles.historyImage} 
          />
        </div>
      </section>

      <Timeline />

      <section className={styles.additionalInfo}>
        <p className={styles.infoText}>
          Com a evolução da discussão do modelo ideal de Previdência Social para o Brasil, o segmento de seguros e previdência privada abriu-se para os bancos em geral, e os produtos das Entidades genuínas de Previdência Privada, foram perdendo sua atratividade comercial.
        </p>
      </section>
    </main>
  );
};

export default InstitutionalHistory;
