import React, { useState, useEffect } from 'react';
import styles from './GallerySection.module.css';
import imagem_1 from '../../images/gallery/galeria_1.png'
import imagem_2 from '../../images/gallery/galeria_2.png'
import imagem_3 from '../../images/gallery/galeria_3.png'
import imagem_4 from '../../images/gallery/galeria_4.png'
import imagem_5 from '../../images/gallery/galeria_5.png'
import imagem_6 from '../../images/gallery/galeria_6.png'
import imagem_7 from '../../images/gallery/galeria_7.png'

const GallerySection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const galleryImages = [
    { 
      src: imagem_1, 
      alt: "Imagem da galeria 1", 
      caption: "Uma das classes mantidas pela Sociedade no início do século XX" 
    },
    { 
      src: imagem_2, 
      alt: "Imagem da galeria 2", 
      caption: "Celebrando momentos especiais na história da SCMS." 
    },
    { 
      src: imagem_3, 
      alt: "Imagem da galeria 3", 
      caption: "Sede da SCMS." 
    },
    { 
      src: imagem_4, 
      alt: "Imagem da galeria 4", 
      caption: "Diretoria da Sociedade em meados de 1920." 
    },
    { 
      src: imagem_5, 
      alt: "Imagem da galeria 5", 
      caption: "Sede da Sociedade em meados de 1920. A sede primitiva consistia apenas no andar térreo, e não ostentava decorações na fachada." 
    },
    { 
      src: imagem_6, 
      alt: "Imagem da galeria 6", 
      caption: "Membros da Sociedade em 11 de novembro de 1896, nas comemorações do 27º aniversário de seu patrono Vítor Emanuel." 
    },
    { 
      src: imagem_7, 
      alt: "Imagem da galeria 7", 
      caption: "Primeira sede da Sociedade Príncipe de Nápoles." 
    }
  ];

  const changeImage = (newIndex) => {
    if (transitioning || newIndex === activeIndex) return;
    
    setTransitioning(true);
    const currentImage = document.querySelector(`.${styles.active}`);
    if (currentImage) {
      currentImage.classList.add(styles.exiting);
    }

    setTimeout(() => {
      setActiveIndex(newIndex);
      setTransitioning(false);
    }, 600);
  };

  const nextImage = () => {
    const newIndex = (activeIndex + 1) % galleryImages.length;
    changeImage(newIndex);
  };

  const prevImage = () => {
    const newIndex = activeIndex === 0 ? galleryImages.length - 1 : activeIndex - 1;
    changeImage(newIndex);
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (transitioning) return;

      switch (e.key) {
        case 'ArrowLeft':
          prevImage();
          break;
        case 'ArrowRight':
          nextImage();
          break;
        case 'Escape':
          if (isFullscreen) setIsFullscreen(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [transitioning, isFullscreen, activeIndex]);

  // Touch handlers
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;

    const touchEnd = e.touches[0].clientX;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        nextImage();
      } else {
        prevImage();
      }
      setTouchStart(null);
    }
  };

  const toggleFullscreen = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsFullscreen(!isFullscreen);
  };

  const getImageClass = (index) => {
    if (index === activeIndex) return styles.active;
    if (transitioning && index === activeIndex) return styles.entering;
    return '';
  };

  return (
    <section className={styles.gallerySection}>
      <h1 className={styles.galleryTitle}>Galeria</h1>
      <div className={styles.titleUnderline} />

      <div className={styles.galleryContainer}>
        <div 
          className={styles.mainGallery}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {galleryImages.map((image, index) => (
            <div
              key={index}
              className={`${styles.galleryImage} ${getImageClass(index)}`}
            >
              <img
                src={image.src}
                alt={image.alt}
                loading={index === 0 ? "eager" : "lazy"}
                onClick={toggleFullscreen}
                style={{ cursor: 'pointer' }}
              />
              <button 
                className={styles.fullscreenButton}
                onClick={toggleFullscreen}
                aria-label="Ver em tela cheia"
              >
                ⤢
              </button>
              <div className={styles.imageCaption}>
                {image.caption}
              </div>
            </div>
          ))}

          <button 
            className={`${styles.navButton} ${styles.prevButton}`}
            onClick={(e) => {
              e.stopPropagation();
              prevImage();
            }}
            aria-label="Imagem anterior"
          >
            ‹
          </button>
          <button 
            className={`${styles.navButton} ${styles.nextButton}`}
            onClick={(e) => {
              e.stopPropagation();
              nextImage();
            }}
            aria-label="Próxima imagem"
          >
            ›
          </button>
        </div>

        <div className={styles.thumbnailStrip}>
          {galleryImages.map((image, index) => (
            <div
              key={`thumb-${index}`}
              className={`${styles.thumbnail} ${index === activeIndex ? styles.active : ''}`}
              onClick={() => changeImage(index)}
            >
              <img src={image.src} alt={`Miniatura ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Fullscreen View */}
      <div className={`${styles.fullscreenView} ${isFullscreen ? styles.active : ''}`}>
        <img
          src={galleryImages[activeIndex].src}
          alt={galleryImages[activeIndex].alt}
          className={styles.fullscreenImage}
        />
        <button 
          className={styles.closeFullscreen}
          onClick={toggleFullscreen}
          aria-label="Fechar tela cheia"
        >
          ×
        </button>
      </div>
    </section>
  );
};

export default GallerySection;
