import React from "react";
import styles from './ProductItem.module.css';

function ProductItem() {
    const policies = [
        "Tranquilidade e Segurança Financeira para a Família: O pecúlio oferece a garantia de que, em momentos difíceis, os entes queridos não ficarão desamparados financeiramente.",
        "Os planos de Pecúlio são classificados em Repartição Simples e Capitalização. Sendo que para os planos de Capitalização podem ocorrer a operação de resgate.",
        "Flexibilidade e Personalização: O cliente pode escolher o valor do pecúlio, conforme as necessidades da sua família, com planos que se ajustam ao orçamento e ao perfil de cada um.",
        "Sem Exigência de Exames Médicos: A contratação é facilitada, proporcionando mais agilidade e acessibilidade para todos os interessados.",
        "O Cliente pode indicar o beneficiário, que receberão o valor do benefício contratado pelo participante, o que permite um planejamento conforme o desejo e necessidade do mesmo."
    ];

    return (
        <main className={styles.product}>
            <h2 className={styles.subTitle}>Pecúlio</h2>
            <p className={styles.description}>
                O pecúlio é um produto financeiro de proteção, que garante um amparo para o titular e seus beneficiários em situações inesperadas, como falecimento. Funciona como um seguro de vida ou um fundo de reserva, onde, ao aderir, o titular realiza contribuições periódicas e, em caso de falecimento ou invalidez, os beneficiários recebem um valor previamente estipulado.
                <br /><br />
                <strong>Principais Benefícios do Pecúlio:</strong>
            </p>
            <div className={styles.policiesContainer}>
                {policies.map((policy, index) => (
                    <div key={index} className={styles.policyItem}>
                        <div className={styles.bulletNumber}>{index + 1}</div>
                        <span className={styles.policyText}>{policy}</span>
                    </div>
                ))}
            </div>
        </main>
    );
}

export default ProductItem;
