import React, { useRef, useEffect } from 'react';
import styles from './Modal.module.css';

const Modal = ({ message, onClose, pdfUrl }) => {
  const modalRef = useRef();
  const printFrameRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'LGPD.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrint = () => {
    // Create a hidden iframe if it doesn't exist
    if (!printFrameRef.current) {
      const iframe = document.createElement('iframe');
      iframe.style.visibility = 'hidden';
      iframe.style.position = 'fixed';
      iframe.style.right = '0';
      iframe.style.bottom = '0';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.src = pdfUrl;
      
      printFrameRef.current = iframe;
      document.body.appendChild(iframe);
    }

    // Wait for iframe to load before printing
    printFrameRef.current.onload = () => {
      window.frames[window.frames.length - 1].focus();
      window.frames[window.frames.length - 1].print();
    };
  };

  // Cleanup iframe on unmount
  useEffect(() => {
    return () => {
      if (printFrameRef.current) {
        document.body.removeChild(printFrameRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={modalRef}>
        {pdfUrl ? (
          <div className={styles.pdfContainer}>
            <embed 
              src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`} 
              type="application/pdf" 
              width="100%" 
              height="100%" 
            />
          </div>
        ) : (
          <p className={styles.message}>{message}</p>
        )}
        <div className={styles.buttonContainer}>
          <button className={styles.actionButton} onClick={handlePrint}>
            Imprimir
          </button>
          <button className={styles.actionButton} onClick={handleDownload}>
            Baixar Documento
          </button>
          <button className={styles.closeButton} onClick={onClose}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
