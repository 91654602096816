import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Institucional.module.css';
import family_1 from '../../images/familia_1.svg';
import logo_header from '../../images/logo_header.png';

const Institucional = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <section className={styles.banner}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img
            loading="lazy"
            src={logo_header}
            className={`${styles.logoInstitutional} ${isHomePage ? styles.logoVisible : ''}`}
            alt="SCMS Logo"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.textColumn}>
            <div className={styles.textContent}>
              <h1 className={styles.title}>
              Sociedade Caxiense de Mútuo Socorro <span className={styles.subtitle}>Previdência Privada</span>
              </h1>
              {/*
              <button 
                className={styles.ctaButton}
                onClick={() => navigate('/institucional')}
              >
                Saiba mais
              </button>
              */}
            </div>
          </div>
          <div className={styles.imageColumn}>
            <div className={styles.imageWrapper}>
              <img
                loading="lazy"
                src={family_1}
                className={styles.foregroundImage}
                alt="Ilustração representativa da Sociedade Caxiense de Mútuo Socorro"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Institucional;
