import React from "react";
import styles from './Sustainability.module.css';
import arrow from '../../../images/arrowbackbutton.svg'
import politica from "../../../documents/POLIìTICA_DE_SUSTENTABILIDADE_SCMS_final.pdf"

function Sustainability() {
  const policies = [
    "Código de ética",
    "Política de Segurança Cibernética, da Informação e Privacidade",
    "Política de Combate e Prevenção contra Crimes de Lavagem de Dinheiro",
    "Política de Ocultação de Bens",
    "Política de Direitos e Valores",
    "Política de Gestão de Riscos",
    "Política de Controles Internos",
    "Política de Conformidade",
  ];

  return (
    <main className={styles.sustainability}>
      <h1 className={styles.pageTitle}>Sustentabilidade</h1>
      <div className={styles.titleUnderline}></div>
      <div className={styles.description}>
      <br /><br />
        <p>
          A sustentabilidade é o conceito de atender às necessidades do presente sem comprometer as gerações futuras, 
          equilibrando o desenvolvimento econômico, a proteção ambiental e o bem-estar social. Ela busca promover o 
          uso responsável dos recursos naturais e a adoção de práticas que minimizem os impactos negativos no planeta.
        </p>

        <h3 className={styles.contentTitle}>Três Pilares da Sustentabilidade:</h3>
        <div className={styles.numberedList}>
          <div className={styles.numberedItem}>
            <span className={styles.number}>1.</span>
            <div className={styles.itemContent}>
              <strong>Ambiental:</strong> Preservação dos ecossistemas, redução de poluição e desperdício, uso consciente de 
              recursos naturais e combate às mudanças climáticas.
            </div>
          </div>
          <div className={styles.numberedItem}>
            <span className={styles.number}>2.</span>
            <div className={styles.itemContent}>
              <strong>Social:</strong> Promoção de equidade, inclusão, educação, saúde e qualidade de vida, respeitando os direitos 
              humanos.
            </div>
          </div>
          <div className={styles.numberedItem}>
            <span className={styles.number}>3.</span>
            <div className={styles.itemContent}>
              <strong>Econômico:</strong> Desenvolvimento de práticas econômicas que gerem lucro sem prejudicar o meio ambiente 
              ou a sociedade.
            </div>
          </div>
        </div>

        <h3 className={styles.contentTitle}>Objetivos da Sustentabilidade:</h3>
        <div className={styles.arrowList}>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Garantir recursos naturais para o futuro.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Reduzir desigualdades sociais.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Promover o crescimento econômico de forma responsável.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Estimular a adoção de tecnologias limpas e inovadoras.</span>
          </div>
        </div>

        <h3 className={styles.contentTitle}>Práticas Sustentáveis:</h3>
        <div className={styles.arrowList}>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Uso de energia renovável.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Redução, reutilização e reciclagem de materiais.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Incentivo ao consumo consciente.</span>
          </div>
          <div className={styles.arrowItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.arrowIcon} />
            <span className={styles.arrowText}>Proteção de florestas e biodiversidade.</span>
          </div>
        </div>

        <p>
          A sustentabilidade é fundamental para a criação de um futuro mais justo, equilibrado e próspero para todos.
        </p>

        <p>
          A Sociedade Caxiense de Mútuo Socorro (SCMS) vem trabalhando para adotar práticas de ESG (Environmental, Social and Governance), considerando aspectos ambientais, sociais e de Governança em suas operações.
        </p>
        <p>
          <strong>Nesse sentido, a Entidade já implantou:</strong>
        </p>
      </div>

      <div className={styles.policiesContainer}>
        {policies.map((policy, index) => (
          <div key={index} className={styles.policyItem}>
            <img loading="lazy" src={arrow} alt="" className={styles.policyIcon} />
            <span className={styles.policyText}>{policy}</span>
          </div>
        ))}
      </div>
      <p className={styles.additionalInfo}>
        <a href={politica} className={styles.policyLink} download>POLÍTICA DE SUSTENTABILIDADE</a>
        <br /><br />
      </p>
    </main>
  );
}

export default Sustainability;
