import React, { useState, useEffect } from 'react';
import styles from './CookieConsent.module.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [preferences, setPreferences] = useState(() => {
    const savedPreferences = localStorage.getItem('cookieConsent');
    if (savedPreferences) {
      return JSON.parse(savedPreferences);
    }
    return {
      necessary: true,
      analytics: false,
      marketing: false,
      preferences: false
    };
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const allPreferences = {
      necessary: true,
      analytics: true,
      marketing: true,
      preferences: true
    };
    setPreferences(allPreferences);
    localStorage.setItem('cookieConsent', JSON.stringify(allPreferences));
    setIsVisible(false);
  };

  const handleRejectAll = () => {
    const minimalPreferences = {
      necessary: true,
      analytics: false,
      marketing: false,
      preferences: false
    };
    setPreferences(minimalPreferences);
    localStorage.setItem('cookieConsent', JSON.stringify(minimalPreferences));
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setIsVisible(false);
  };

  const handleTogglePreference = (key) => {
    if (key === 'necessary') return;
    const newPreferences = {
      ...preferences,
      [key]: !preferences[key]
    };
    setPreferences(newPreferences);
  };

  const handlePrivacyButtonClick = () => {
    setIsVisible(true);
    setShowCustomize(true);
  };

  // Função para aplicar as preferências de cookies
  const applyCookiePreferences = (prefs) => {
    // Aqui você pode adicionar lógica para aplicar as preferências
    // Por exemplo, ativar/desativar scripts de analytics, marketing, etc.
    if (prefs.analytics) {
      // Ativar analytics
      console.log('Analytics ativado');
    }
    if (prefs.marketing) {
      // Ativar cookies de marketing
      console.log('Marketing ativado');
    }
    if (prefs.preferences) {
      // Ativar cookies de preferências
      console.log('Preferências ativadas');
    }
  };

  // Aplicar preferências quando o componente montar e quando as preferências mudarem
  useEffect(() => {
    if (localStorage.getItem('cookieConsent')) {
      applyCookiePreferences(preferences);
    }
  }, [preferences]);

  return (
    <>
      {!isVisible && localStorage.getItem('cookieConsent') && (
        <button onClick={handlePrivacyButtonClick} className={styles.privacyButton} aria-label="Configurações de Privacidade">
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={styles.lockIcon}
          >
            <path 
              d="M12 1C8.676 1 6 3.676 6 7V9H4C3.448 9 3 9.448 3 10V21C3 21.552 3.448 22 4 22H20C20.552 22 21 21.552 21 21V10C21 9.448 20.552 9 20 9H18V7C18 3.676 15.324 1 12 1ZM12 3C14.276 3 16 4.724 16 7V9H8V7C8 4.724 9.724 3 12 3ZM12 15C13.104 15 14 15.896 14 17C14 18.104 13.104 19 12 19C10.896 19 10 18.104 10 17C10 15.896 10.896 15 12 15Z" 
              fill="currentColor"
            />
          </svg>
        </button>
      )}
      
      {isVisible && (
        <div className={styles.cookieConsent}>
          <div className={styles.mainContent}>
            <h2>Política de Cookies</h2>
            <p>
              Utilizamos cookies para melhorar sua experiência em nosso site. Alguns cookies são essenciais para o funcionamento do site, 
              enquanto outros nos ajudam a entender como você interage com nosso conteúdo.
            </p>

            {!showCustomize ? (
              <div className={styles.buttons}>
                <button onClick={handleAcceptAll} className={styles.acceptButton}>
                  Aceitar Todos
                </button>
                <button onClick={handleRejectAll} className={styles.rejectButton}>
                  Rejeitar Todos
                </button>
                <button onClick={() => setShowCustomize(true)} className={styles.customizeButton}>
                  Personalizar
                </button>
              </div>
            ) : (
              <div className={styles.customizeSection}>
                <div className={styles.preferenceItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={preferences.necessary}
                      disabled
                    />
                    Necessários (Obrigatório)
                  </label>
                  <p>Essenciais para o funcionamento do site</p>
                </div>

                <div className={styles.preferenceItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={preferences.analytics}
                      onChange={() => handleTogglePreference('analytics')}
                    />
                    Analytics
                  </label>
                  <p>Nos ajudam a entender como você usa o site</p>
                </div>

                <div className={styles.preferenceItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={preferences.marketing}
                      onChange={() => handleTogglePreference('marketing')}
                    />
                    Marketing
                  </label>
                  <p>Usados para publicidade direcionada</p>
                </div>

                <div className={styles.preferenceItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={preferences.preferences}
                      onChange={() => handleTogglePreference('preferences')}
                    />
                    Preferências
                  </label>
                  <p>Armazenam suas preferências de navegação</p>
                </div>

                <div className={styles.customizeButtons}>
                  <button onClick={handleSavePreferences} className={styles.saveButton}>
                    Salvar Preferências
                  </button>
                  <button onClick={() => setShowCustomize(false)} className={styles.backButton}>
                    Voltar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
