import React, { useState } from "react";
import styles from './Header.module.css';
import logo_header from '../../images/logo_header.png';
import pin_header from '../../images/pin_gps_location.png';
import { useNavigate, useLocation } from "react-router-dom";
import Modal from '../Modal/Modal';
import lgpdPdf from '../../documents/LGPD.pdf';

const navItems = [
  { title: "Home", path: "/", isActive: true },
  { 
    title: "Institucional", 
    isActive: false,
    submenu: [
      { title: "História", path: "/historia" },
      { title: "Sustentabilidade", path: "/sustentabilidade" },
      { title: "Princípios da entidade", path: "/institucional" },
      { title: "LGPD", action: 'showLgpdPdf' }
    ]
  },
  { title: "Informações", path: "/informacoes", isActive: false },
  { title: "Produto", path: "/produto", isActive: false },
  { title: "Galeria", path: "/galeria", isActive: false },
  { title: "Fale conosco", path: "/contato", isActive: false }
];

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleNavItemClick = (index, item) => {
    if (activeNavItem === index) {
      setActiveNavItem(null); // Close submenu if clicking the same item
    } else {
      setActiveNavItem(index); // Open submenu for new item
      if (item.path) {
        navigate(item.path);
        setActiveNavItem(null); // Close submenu after navigation
      }
    }
  };

  const handleSubNavItemClick = (item, event) => {
    event.stopPropagation();
    if (item.action === 'showLgpdPdf') {
      setShowModal(true);
    } else if (item.path) {
      navigate(item.path);
    }
    setActiveNavItem(null); // Close submenu after action
  };

  // Close submenu when clicking outside
  React.useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (!event.target.closest(`.${styles.navLinks}`)) {
    //     setActiveNavItem(null);
    //   }
    // };

    // document.addEventListener('mousedown', handleClickOutside);
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // };
  }, []);

  const isHomePage = location.pathname === '/';

  return (
    <header className={styles.header}>
      <div className={styles.topBar}>
        <div className={styles.addressWrapper}>
        </div>
      </div>
      <nav className={styles.navbar}>
        <img 
          loading="lazy" 
          src={logo_header} 
          className={`${styles.logo} ${isHomePage ? styles.logoHome : styles.logoInstitutional}`} 
          alt="SCMS - Sociedade Caxiense de Mútuo Socorro" 
        />
        <ul className={styles.navLinks}>
          {navItems.map((item, index) => (
            <li
              key={index}
              className={index === activeNavItem ? styles.activeLink : styles.navLink}
              onClick={() => handleNavItemClick(index, item)}
            >
              {item.title}
              {index === activeNavItem && <div className={styles.activeLinkUnderline} />}
              {item.submenu && index === activeNavItem && (
                <ul className={styles.submenu}>
                  {item.submenu.map((submenuItem, subIndex) => (
                    <li 
                      key={subIndex} 
                      className={styles.submenuItem} 
                      onClick={(e) => handleSubNavItemClick(submenuItem, e)}
                    >
                      <span className={styles.submenuText}>{submenuItem.title}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      {showModal && (
        <Modal
          pdfUrl={lgpdPdf}
          onClose={() => setShowModal(false)}
        />
      )}
    </header>
  );
}

export default Header;
