import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Objective.module.css';

const Objective = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.objective}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          Qual objetivo do segmento de previdência complementar?
        </h2>
        <p className={styles.description}>
          Instituir e operar planos de benefícios de caráter previdenciário concedidos em forma de renda continuada (aposentadoria) ou pagamento único (pecúlio), acessíveis a qualquer pessoa física.
        </p>
        <div className={styles.buttonGroup}>
          <button className={styles.primaryButton} onClick={() => navigate('./produto')}>Saiba mais</button>
          <button className={styles.secondaryButton} onClick={() => navigate('./contato')}>Fale conosco</button>
        </div>
      </div>
    </section>
  );
};

export default Objective;